import React from 'react';
import images from './images.js';
import './App.css';
import { Link } from 'react-router-dom';
import Select from "react-select";
import InfiniteScroll from 'react-infinite-scroller';
import ImageFadeIn from "react-image-fade-in"
import Helmet from 'react-helmet'
import { isAndroid as isAndroidWeb } from 'react-device-detect'
import useTmdb from './useTmdb.js';
import { RuxSlider, RuxInput } from '@astrouxds/react'

const oneMonth = 30 * 24 * 60 * 60 * 1000

const periods = {
  '3mo': 3 * oneMonth,
  '6mo': 6 * oneMonth,
  '1yr': 12 * oneMonth,
  '2yr': 2 * 12 * oneMonth,
  '3yr': 3 * 12 * oneMonth,
}

const periodLabels = {
  '3mo': '3 Months',
  '6mo': '6 Months',
  '1yr': '1 Year',
  '2yr': '2 Years',
  '3yr': '3 Years',
}

const refreshTime = 30 * 60 * 1000 // 30min

const paginate = 100

const ratingUserOpts = {
  'imdb.audience': { name: 'IMDB', votesMax: 10000, scoreMax: 10 },
  'trakt.audience': { name: 'Trakt', votesMax: 5000 },
  'rogerebert.critics': { name: 'Roger Ebert', votesMax: 0, scoreMax: 5 },
  'tmdb.audience': { name: 'TMDB', votesMax: 5000 },
  'letterboxd.audience': { name: 'Letterboxd', votesMax: 10000, scoreMax: 10 },
  'metacritic.critics': { name: 'Metacritic', votesMax: 500 },
  'rt.critics': { name: 'Tomatoes Critics', votesMax: 500, noVotesForSeries: true },
  'rt.audience': { name: 'Tomatoes Audience', votesMax: 1000, noVotesForSeries: true },
  'mal.audience': { name: 'MAL', votesMax: 500, scoreMax: 10 },
}

function imageResize(posterUrl, width) {
  if (!posterUrl) return null
  if (!posterUrl.includes('amazon.com') && !posterUrl.includes('imdb.com')) return posterUrl
  if (posterUrl.includes('._V1_.')) posterUrl = posterUrl.replace('._V1_.', '._V1_SX' + width + '.')
  else if (posterUrl.includes('._V1_')) {
    var extension = posterUrl.split('.').pop()
    posterUrl = posterUrl.substr(0,posterUrl.indexOf('._V1_')) + '._V1_SX' + width + '.' + extension
  }
  return posterUrl
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function isEnglish(el) {
        if ((el.meta || {}).originalLang) {
          return el.meta.originalLang === 'en'
        }
        let eng = 0
        let foreign = 0
        let notKnown = 0

        if (((el.meta || {}).spokenLanguages || []).includes('en'))
          eng++
        else if (((el.meta || {}).spokenLanguages || []).length)
          foreign++
        else
          notKnown++

        if (((el.meta || {}).origins || []).some(country => ['US', 'GB', 'CA', 'IE', 'AU'].includes(country)))
          eng++
        else if (((el.meta || {}).origins || []).length)
          foreign++
        else
          notKnown++

        if (((el.meta || {}).studios || []).some(studio => ['US', 'GB', 'CA', 'IE', 'AU'].includes((studio || {}).country || '')))
          eng++
        else if (((el.meta || {}).studios || []).length && ((el.meta || {}).studios || []).find(studio => !!studio.country))
          foreign++
        else notKnown++

        return !!(eng > foreign || notKnown === 3)
}

function isCam(el) {
	return (el || {}).isCam || false
}

export default class App extends React.Component {
  constructor(props) {
      super(props);

      let locTypes = props.location.pathname.split('/')
      const type = locTypes[1]
      const listType = type === 'movies' ? 'movie' : type

      const ratingsOrder = localStorage.getItem('key') && localStorage.getItem('ratingsOrder') ? localStorage.getItem('ratingsOrder') : 'imdb.audience,metacritic.critics,rt.critics'
      const ratings = ratingsOrder.split(',').map(el => {
        const noVotesForSeries = ratingUserOpts[el].noVotesForSeries
         return {
           min: 0,
           max: 100,
           votes: 0,
           votesMax: listType !== 'movie' && noVotesForSeries ? 0 : ratingUserOpts[el].votesMax,
           name: ratingUserOpts[el].name,
           scoreMax: ratingUserOpts[el].scoreMax,
         }
      })

      let period
      const order = locTypes[2]

      if (order === 'best' && localStorage.getItem('key')) {
        if (locTypes[3] && periods[locTypes[3]]) {
          period = locTypes[3]
        }
      }

      this.state = {
          data: [],
          hasMoreItems: true,
          limit: paginate,
          ratings,
          ratingsOrder,
          filters: true,
          period,
      };
  }
  processList(data) {

      const ratingsOrder = this.state.ratingsOrder

      const interestingRatings = ratingsOrder.split(',').map(el => ({ key: el.split('.')[0], type: el.split('.')[1] }))

//      let filters = false
      let filters = true
      let locTypes = this.props.location.pathname.split('/')
      locTypes.shift()
      const type = locTypes[0]
      const listType = type === 'movies' ? 'movie' : type
      const order = locTypes[1]

      let lastTime = 0

      let period = '3mo'

      if (order === 'best' && localStorage.getItem('key')) {
        if (periods[locTypes[2]]) {
          // other params become off by one
          period = locTypes[2]
          locTypes.shift()
        }
        lastTime = Date.now() - periods[period]
      }

      let langSelect = 'English'
      if (locTypes[2]) {
        filters = true
        langSelect = capitalizeFirstLetter(locTypes[2])
      }
      let genreSelect = 'none'
      if (locTypes[3]) {
        filters = true
        genreSelect = capitalizeFirstLetter(locTypes[3])
        if (genreSelect === 'Sci-fi')
          genreSelect = 'Sci-Fi'
      }
      const elements = data[listType]
      let ordered
      if (order === 'new') {
        ordered = Object.values(elements).sort((a, b) => {
          const dateA = new Date(a.pubdate)
          const dateB = new Date(b.pubdate)
          return dateA < dateB ? 1 : dateA > dateB ? -1 : 0
        })
      } else if (order === 'popular') {
        ordered = data[listType + 'PopularityOrder'].map(el => {
          if (!elements[el]) {
            console.log(el)
          }
          return elements[el]
        }).filter(el => !!el)
        console.log(ordered)
      } else if (order === 'best') {

        ordered = Object.values(elements).filter(el => {

          if (lastTime)
            if (el.pubdate && new Date(el.pubdate).getTime() < lastTime)
              return false

          let atLeastCount = 0

	  interestingRatings.forEach(rating => {
            if (((((el.meta || {}).ratings || {})[rating.key] || {})[rating.type] || {}).score)
              atLeastCount++
          })

          if (this.state.langSelect === 'Foreign')
            return !!(atLeastCount >= 1)
          else if (type === 'other')
            return !!(atLeastCount >= 1)
          else if (type === 'series')
            return !!(atLeastCount > 1)
          else if (type === 'movies')
            return !!(atLeastCount > 2)
          else
            return false
        }).map(el => {
          const ratings = []
          el.overall = 0
          if (Object.keys((el.meta || {}).ratings || {}).length) {
            Object.keys(el.meta.ratings).forEach(key => {
              const rating = el.meta.ratings[key]
              interestingRatings.forEach(tRating => {
                if (tRating.key === key && (rating[tRating.type] || {}).score) {
                  const correction = key === 'imdb' || key === 'letterboxd' ? 10 : 1
                  ratings.push(rating[tRating.type].score * correction)
                }
              })
            })
            if (ratings.length) {
              ratings.forEach(elm => {
                el.overall += elm
              })
              el.overall = el.overall / ratings.length
            }
          }

          return el
        }).sort((a, b) => {
          return a.overall < b.overall ? 1 : a.overall > b.overall ? -1 : 0
        })
      }

      ordered = ordered.filter(el => { return !!el.meta })

      const genreOpts = [
        { value: 'none', label: 'Any Genre' }
      ]

      const noGenreDups = []

      ordered.forEach(el => {
        if (((el.meta || {}).genres || []).length) {
          el.meta.genres.forEach(genre => {
            if (!noGenreDups.includes(genre))
              noGenreDups.push(genre)
          })
        }
      })

      noGenreDups.sort().forEach(genre => {
        genreOpts.push({
          value: genre, label: genre
        })
      })

      const langOpts = [
        {
          value: 'English', label: 'English'
        },
        {
          value: 'Foreign', label: 'Foreign'
        },
        {
          value: 'International', label: 'International'
        },
      ]

      const total = ordered.length

      const limited = ordered.slice(0, this.state.limit)

      const isAndroid = ((window.navigator || {}).userAgent || '').endsWith('AndroidApp')

      this.setState({ initLoading: false, filters, all: ordered, ordered: limited, period, hasMoreItems: !!(limited.length < total), type, order, genreOpts, genreSelect, langOpts, langSelect, isAndroid }, this.afterSetState)

  }
  componentWillMount() {
      if (this.props?.history?.action === 'POP' && this.props?.history?.location?.state) {
        const isRefresh = localStorage.getItem('lastKnownUrl') === window.location.pathname + window.location.search

        if (!isRefresh) {
          localStorage.setItem('lastKnownUrl', window.location.pathname + window.location.search)

          this.setState(this.props.history.location.state)
          return
        } else {
          setTimeout(() => {
            window.scrollTo(0,0)
          },100)
        }
      }

      localStorage.setItem('lastKnownUrl', window.location.pathname + window.location.search)

      const data = require('./data/lists.json')

      const key = localStorage.getItem('key');

      if (key) {

       fetch(`https://api.torrent-track.app/${key}/get`)
         .then(response => response.json())
         .then(allData => {
           const data = { movie: {}, series: {}, other: {}, moviePopularityOrder: allData.popularLists.movie, seriesPopularityOrder: allData.popularLists.series, otherPopularityOrder: allData.popularLists.other }
           allData.allItems.forEach(item => {
             if (allData.others.includes(item.ids.imdb)) item.type = 'other'
             if (data[item.type]) data[item.type][item.ids.imdb] = { pubdate: item.pubdate, meta: item, episode_info: { imdb: item.ids.imdb } }
           })
           Object.keys((data || {}).movie || {}).forEach(key => {
             if (data.movie[key].isCam)
               delete data.movie[key]
           })
           this.processList(data)
         })
         .catch(error => console.error('Error:', error));
         this.setState({ genreOpts: [], langOpts: [], all: [], ordered: [] }, this.afterSetState)
      } else {

        const data = require('./data/lists.json')
        // filter out all CAMs

        Object.keys((data || {}).movie || {}).forEach(key => {
            if (data.movie[key].isCam)
  		delete data.movie[key]
        })

        this.processList(data)

      }

  }
  moreFilters() {
    this.setState({ filters: true }, this.afterSetState)
  }
  openRatingsSettings() {
    this.setState({ ratingsOpen: true }, this.afterSetState)
  }
  pageTitle() {
    let docTitle = ''
    if (this.state.order === 'new') {
      docTitle += 'New '
    } else if (this.state.order === 'popular') {
      docTitle += 'Popular '
    } else if (this.state.order === 'best') {
      docTitle += 'Best '
    }
    if (this.state.langSelect !== 'English') {
      docTitle += this.state.langSelect + ' '
    }
    if (this.state.genreSelect !== 'none') {
      docTitle += this.state.genreSelect + ' '
    }
    if (this.state.type === 'movies') {
      docTitle += 'Movies '
    } else if (this.state.type === 'series') {
      docTitle += 'Series '
    } else if (this.state.type === 'other') {
      docTitle += 'Other '
    }
    return docTitle.slice(0, -1)
  }
  pageKeywords() {
    const keywords = []
    keywords[0] = ''
    if (this.state.order === 'new') {
      keywords[0] += 'new '
      keywords[1] = 'new'
    } else if (this.state.order === 'popular') {
      keywords[0] += 'popular '
      keywords[1] = 'popular'
    } else if (this.state.order === 'best') {
      keywords[0] += 'best '
      keywords[1] = 'best'
    }
    if (this.state.langSelect && this.state.langSelect !== 'English') {
      keywords[0] += this.state.langSelect.toLowerCase() + ' '
      keywords[2] = this.state.langSelect.toLowerCase()
    }
    if (this.state.genreSelect && this.state.genreSelect !== 'none') {
      keywords[0] += this.state.genreSelect.toLowerCase() + ' '
      keywords[3] = this.state.genreSelect.toLowerCase()
    }
    if (this.state.type === 'movies') {
      keywords[0] += 'movies '
      keywords[4] = 'movies'
    } else if (this.state.type === 'series') {
      keywords[0] += 'series '
      keywords[4] = 'series'
    } else if (this.state.type === 'other') {
      keywords[0] += 'other '
      keywords[4] = 'other'
    }
    keywords[0] += 'torrent releases'
    keywords[5] = 'track torrents'
    keywords[6] = 'torrent track'
    return keywords.filter(el => !!el).join(', ')
  }
  pageDescription() {
    let docDesc = 'Find '
    if (this.state.order === 'new') {
      docDesc += 'new '
    } else if (this.state.order === 'popular') {
      docDesc += 'popular '
    } else if (this.state.order === 'best') {
      docDesc += 'the best '
    }
    if (this.state.genreSelect && this.state.genreSelect !== 'none') {
      docDesc += this.state.genreSelect.toLowerCase() + ' '
    }
    if (this.state.type === 'movies') {
      docDesc += 'movie '
    } else if (this.state.type === 'series') {
      docDesc += 'series '
    } else if (this.state.type === 'other') {
      docDesc += 'other '
    }
    docDesc += 'torrent releases with IMDB, Metacritic, Rotten Tomatoes scores.'
    return docDesc
  }
  updateUrl(genre, lang, period, refresh) {
    let newUrl = '/' + this.state.type + '/' + this.state.order
    period = period || this.state.period || '3mo'
    if (this.state.order === 'best' && period !== '3mo')
      newUrl += '/' + period
    if (genre !== 'none') {
      newUrl += '/' + lang.toLowerCase() + '/' + genre.toLowerCase()
    } else if (lang !== 'English') {
      newUrl += '/' + lang.toLowerCase()
    }
    if (refresh) {
      window.location.replace(newUrl)
//      window.history.replaceState({ key: window.history?.state?.key, value: null }, null, newUrl)
    } else if (typeof (window.history || {}).replaceState !== 'undefined') {
      window.history.replaceState(null, null, newUrl)
      localStorage.setItem('lastKnownUrl', window.location.pathname + window.location.search)
    }
  }
  handleLangSelect(e) {
    this.updateUrl(this.state.genreSelect, e.value)
    this.setState({
        langSelect: e.value,
    }, this.afterSetState);
  }
  handleGenreSelect(e) {
    this.updateUrl(e.value, this.state.langSelect)
    this.setState({
        genreSelect: e.value,
    }, this.afterSetState);
  }
  handlePeriodSelect(e) {
    this.updateUrl(this.state.genreSelect, this.state.langSelect, e.value, true)
    this.setState({
        period: e.value,
    }, this.afterSetState);
  }
  afterSetState() {
    const key = window.history?.state?.key || (window.location.pathname + window.location.search)
    window.history.replaceState({ key, state: this.state }, null)
  }
  loadMore() {
    if (this.loading)
      return
    this.loading = true
    const limit = this.state.limit + paginate;
    const total = this.state.all.length;
console.log('total', total)
console.log('limit', limit)

    const limited = this.state.all.slice(0, limit);
console.log({ ordered: limited, hasMoreItems: !!(limited.length < total) })
    this.setState({ ordered: limited, limit, hasMoreItems: !!(limited.length < total) }, this.afterSetState)
    setTimeout(() => {
      this.loading = false
    })
  }
  agreedLegal() {
    window.localStorage.agreedLegal = '1'
    this.setState({ agreedLegal: true }, this.afterSetState)
  }
  render() {
    if (this.state.initLoading) {
      return (
        <div className="loading">
          <div />
          <div />
        </div>
      )
    }
    let elements = []
    let langOrder = []
console.log('this.state.ordered', this.state.ordered)
    if (this.state.langSelect === 'English') {
      // allow only english:
      langOrder = this.state.ordered.filter(el => isEnglish(el))
    } else if (this.state.langSelect === 'Foreign') {
      langOrder = this.state.ordered.filter(el => !isEnglish(el))
    } else if (this.state.langSelect === 'International') {
      // no ordering needed
      langOrder = this.state.ordered
    }

console.log('langOrder', langOrder)

    const ratingsOrder = this.state.ratingsOrder.split(',')

    langOrder.forEach((el, ij) => {
      if (this.state.genreSelect !== 'none') {
        if (!((el.meta || {}).genres || []).length)
          return
        if (!el.meta.genres.includes(this.state.genreSelect))
          return
      }
      let shouldHide = false
      this.state.ratings.forEach((elm, idx) => {
        const ratingType = ratingsOrder[idx].split('.')[0]
        const ratingSubType = ratingsOrder[idx].split('.')[1]
        const score = (((el.meta.ratings || {})[ratingType] || {})[ratingSubType] || {}).score
        const votes = (((el.meta.ratings || {})[ratingType] || {})[ratingSubType] || {}).votes
        if ((elm.min !== 0 || elm.max !== 100) && !score) {
          console.log('should hide')
          console.log('score min', elm.min)
          console.log('score max', elm.max)
          console.log('score', score)
          shouldHide = true
        }
        if (!score && elm.votesMax && elm.votes !== 0) {
          shouldHide = true
        }
        if (score && (score < (elm.scoreMax || 100)*(elm.min/100) || score > (elm.scoreMax || 100)*(elm.max/100)))
          shouldHide = true
        if (votes && elm.votesMax && elm.votes && votes < elm.votes)
          shouldHide = true
      })
      if (shouldHide)
        return
      let ratings = { __html: '' }
      if ((((el.meta.ratings || {})[ratingsOrder[0].split('.')[0]] || {})[ratingsOrder[0].split('.')[1]] || {}).score) {
        ratings.__html += '<div class="imdb-small" style="vertical-align: middle; margin-top: -3px"></div>&nbsp;&nbsp;' + el.meta.ratings[ratingsOrder[0].split('.')[0]][ratingsOrder[0].split('.')[1]].score + '/10'
      }
      if ((((el.meta.ratings || {})[ratingsOrder[1].split('.')[0]] || {})[ratingsOrder[1].split('.')[1]] || {}).score) {
        if (ratings.__html)
          ratings.__html += '&nbsp;&nbsp;&nbsp;&nbsp;'
        ratings.__html += '<div class="mc-small" style="vertical-align: middle; margin-top: -3px"></div>&nbsp;&nbsp;' + el.meta.ratings[ratingsOrder[1].split('.')[0]][ratingsOrder[1].split('.')[1]].score + '%'
      }
      if ((((el.meta.ratings || {})[ratingsOrder[2].split('.')[0]] || {})[ratingsOrder[2].split('.')[1]] || {}).score) {
        if (ratings.__html)
          ratings.__html += '&nbsp;&nbsp;&nbsp;&nbsp;'
        ratings.__html += '<div class="tomato-small" style="vertical-align: middle; margin-top: -3px"></div>&nbsp;&nbsp;' + el.meta.ratings[ratingsOrder[2].split('.')[0]][ratingsOrder[2].split('.')[1]].score + '%'
      }
      if (!ratings.__html)
        ratings.__html = '&nbsp;'
      const genres = { __html: (el.meta.genres || []).length ? el.meta.genres.slice(0, 3).join(' / ') : '&nbsp;' }
      const newEl = (
          <Link className="blank-link" to={'/title/' + el.episode_info.imdb}>
            <div key={ij} style={{ display: 'inline-block', margin: '15px', maxWidth: '250px', width: '250px', verticalAlign: 'top' }}>
                <div style={{ display: ratings.__html.length ? 'block' : 'none', fontSize: '16px', backgroundColor: 'rgba(0,0,0,0.3)', padding: '10px 0', opacity: '0.8' }} dangerouslySetInnerHTML={ratings}/>
                <div style={{ width: '250px', height: '370px', backgroundColor: 'rgba(0,0,0,0.1)' }}>
                  <ImageFadeIn src={useTmdb.forPosters.includes(el.episode_info.imdb) && el.meta.tmdbPoster ? el.meta.tmdbPoster : imageResize(el.meta.poster, 350)} loading={'lazy'} alt={(el.meta || {}).name + ' poster'} width={250} style={{ objectFit: 'cover', width: '100%', height: '100%'}}/>
                </div>
                <div style={{ fontSize: '15px', backgroundColor: 'rgba(0,0,0,0.3)', padding: '10px 0', opacity: '0.8' }}>
                  <span style={{ opacity: '0.7' }} dangerouslySetInnerHTML={genres}/>
                </div>
                <br/>
                <div>{el.meta.name + (el.meta.year ? ' (' + el.meta.year + ')' : '')}</div>
                <br/>
                <br/>
            </div>
          </Link>
      );
      elements.push(newEl)
    })

    let noItemsMessage = ('')

    if (!elements.length) {
      noItemsMessage = (
        <div style={{ padding: '20px', margin: '20px 0', textAlign: 'center', background: 'rgba(0,0,0,0.3)', opacity: '0.4', marginBottom: '45px' }}>
          No results.
        </div>
      )
    }

    let stickyLegal = ('')

    if (!window.localStorage.agreedLegal && !this.state.isAndroid) {
      stickyLegal = (
        <div className="sticky-legal" style={{ display: 'block' }}>
          The use of this service presumes that you agree with the cookie / privacy policies, as well as the disclaimer, which can be found <Link className="blank-link" to={'/legal'} style={{ textDecoration: 'underline' }}>here</Link>.
          <div style={{ height: '15px' }}/>
          <div className="main-links" onClick={this.agreedLegal.bind(this)} style={{ fontSize: '15px', padding: '5px', marginRight: '0', marginLeft: '0'}}>I understand</div>
        </div>
      )
    }

    let urlTail = ''

    urlTail += this.state.langSelect && this.state.langSelect !== 'English' ? '/' + this.state.langSelect.toLowerCase() : ''
    urlTail += this.state.genreSelect && this.state.genreSelect !== 'none' ? '/' + this.state.genreSelect.toLowerCase() : ''

    const handleChange = (e, idx) => {
      const el = this.state.ratings[idx]
      const updatedRatingsOpts = this.state.ratings
      if (e.target.minVal !== undefined) {
        updatedRatingsOpts[idx] = { min: Math.min(e.target.value, e.target.minVal), max: Math.max(e.target.value, e.target.minVal), votes: el.votes, votesMax: el.votesMax, scoreMax: el.scoreMax, name: el.name }
      } else {
        updatedRatingsOpts[idx] = { min: el.min, max: el.max, votes: e.target.value, votesMax: el.votesMax, scoreMax: el.scoreMax, name: el.name }
      }
      this.setState({ ratings: updatedRatingsOpts }, this.afterSetState)
    }

    const onInputChange = (e, idx, type, max) => {
       const el = this.state.ratings[idx]
       const updatedRatingsOpts = this.state.ratings
       let val = parseInt(e.target.value)
       if (max && val > max) {
         val = max
       }
       if (type === 'min') {
         let minVal = val
         let maxVal = el.max
         if (minVal > maxVal) {
            minVal = maxVal
            maxVal = val
         }
         updatedRatingsOpts[idx] = { min: minVal, max: maxVal, votes: el.votes, votesMax: el.votesMax, scoreMax: el.scoreMax, name: el.name }
       } else if (type === 'max') {
         let minVal = el.min
         let maxVal = val
         if (minVal > maxVal) {
            maxVal = minVal
            minVal = val
         }
         updatedRatingsOpts[idx] = { min: minVal, max: maxVal, votes: el.votes, votesMax: el.votesMax, scoreMax: el.scoreMax, name: el.name }
       } else if (type === 'votes') {
         updatedRatingsOpts[idx] = { min: el.min, max: el.max, votes: val, votesMax: el.votesMax, scoreMax: el.scoreMax, name: el.name }
       }
       this.setState({ ratings: updatedRatingsOpts }, this.afterSetState)
    }

    return (
      <div className="App">
        <Helmet>
          <title>{this.pageTitle() + ' on Torrents - Torrent Track'}</title>
          <meta
            name="description"
            content={this.pageDescription()}
          />
          <meta
            name="keywords"
            content={this.pageKeywords()}
          />
        </Helmet>
        <header className="App-header" style={{ marginTop: '65px'}}>
          <div className="sticky" style={{ display: 'block' }}>
            <div className="sticky-inner" style={{ width: '95%' }}>
              <span style={{ float: 'right'}}><Link className={'blank-link meta-watchlist-button'} to={'/watchlist'}>My watchlist</Link></span>
              <h1>{this.pageTitle()}</h1>
            </div>
          </div>
          <br/>
          <div className="android-app-msg" style={{ display: !this.state.isAndroid && isAndroidWeb ? 'block' : 'none' }}>Check out our Android app! <a className="blank-link" href="https://easyupload.io/00v6l4" target="_blank" style={{ textDecoration: 'underline' }}>Download</a></div>
          <Link className="blank-link" to="/">
            <img src={images.logo} className="App-logo" alt="Torrent Track logo" />
          </Link>
          <span className="slogan">The front page of torrents</span>
          <br/>
        </header>
        <Link className={('main-links ' + (this.state.type === 'movies' ? 'main-links-selected' : ''))} to={'/movies/' + this.state.order}>Movies</Link>
        <Link className={('main-links ' + (this.state.type === 'series' ? 'main-links-selected' : ''))} to={'/series/' + this.state.order}>Series</Link>
        <Link className={('main-links ' + (this.state.type === 'other' ? 'main-links-selected' : ''))} style={{ marginRight: '0px' }} to={'/other/' + this.state.order}>Other</Link>
        <div className="mobile-compliance-menu-1" />
        <div className="mobile-compliance-menu-2" />
        <Link className={('main-links ' + (this.state.order === 'new' ? 'main-links-selected' : ''))} to={'/' + this.state.type + '/new'}>New</Link>
        <Link className={('main-links ' + (this.state.order === 'popular' ? 'main-links-selected' : ''))} to={'/' + this.state.type + '/popular'}>Popular</Link>
        <Link className={('main-links ' + (this.state.order === 'best' ? 'main-links-selected' : ''))} style={{ marginRight: '0px' }} to={'/' + this.state.type + '/best'}>Best</Link>
        <div className="mobile-compliance-menu-3" />
        <div className="mobile-compliance-menu-4" />
        <div className="main-links" onClick={this.moreFilters.bind(this)} style={{ fontSize: '15px', border: 'none', padding: '5px', display: this.state.filters ? 'none' : 'inline-block'}}>+ More Filters</div>
        <br/><br/>
        <div className="options-hold" style={{ display: this.state.filters ? 'block' : 'none' }}>
          {
            this.state.order === 'best' && localStorage.getItem('key') ?
              <div className="period-options">
                <Select
                  onChange={this.handlePeriodSelect.bind(this)}
                  menuPlacement="auto"
                  defaultValue={{ value: this.state.period || '3mo', label: periodLabels[this.state.period || '3mo'] }}
                  label="Period"
                  options={Object.keys(periods).map(el => ({ value: el, label: periodLabels[el] }))}
                  isSearchable={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary50: "rgba(255,255,255,0.2)",
                      primary25: "rgba(0,0,0,0.5)",
                      primary: "rgba(255,255,255,0.8)",
                      neutral0: "#282c34",
                      neutral70: "white",
                      neutral80: "white",
                      neutral90: "white"
                    },
                    option: (provided, state) => ({
                      ...provided,
                      color: 'white'
                    })
                  })}
                />
              </div>
            : null
          }
          <div className="genre-options">
            { this.state.genreOpts.length ?
            <Select
              onChange={this.handleGenreSelect.bind(this)}
              menuPlacement="auto"
              defaultValue={this.state.genreOpts[Object.values(this.state.genreOpts).map(el => el.value).indexOf(this.state.genreSelect)]}
              label="Genre"
              options={this.state.genreOpts}
              isSearchable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary50: "rgba(255,255,255,0.2)",
                  primary25: "rgba(0,0,0,0.5)",
                  primary: "rgba(255,255,255,0.8)",
                  neutral0: "#282c34",
                  neutral70: "white",
                  neutral80: "white",
                  neutral90: "white"
                },
                option: (provided, state) => ({
                  ...provided,
                  color: 'white'
                })
              })}
            />
            : null }
          </div>
          <div className="lang-options">
            { this.state.langOpts.length ?
            <Select
              onChange={this.handleLangSelect.bind(this)}
              menuPlacement="auto"
              defaultValue={this.state.langOpts[Object.values(this.state.langOpts).map(el => el.value).indexOf(this.state.langSelect)]}
              label="Language"
              options={this.state.langOpts}
              isSearchable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary50: "rgba(255,255,255,0.2)",
                  primary25: "rgba(0,0,0,0.5)",
                  primary: "rgba(255,255,255,0.8)",
                  neutral0: "#282c34",
                  neutral70: "white",
                  neutral80: "white",
                  neutral90: "white"
                },
                option: (provided, state) => ({
                  ...provided,
                  color: 'white'
                })
              })}
            />
            : null }
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          { localStorage.getItem('key') ? 
              !this.state.ratingsOpen ? 
                <div className="main-links" onClick={this.openRatingsSettings.bind(this)} style={{ fontSize: '15px', border: 'none', padding: '5px', display: 'inline-block', position: 'relative', top: '-7px', marginBottom: '5px' }}>+ Ratings Filters</div>
              : this.state.ratings.map((el, idx) => 
                <div style={{ width: '400px', maxWidth: '90%', padding: '0 10px', display: 'inline-block', textAlign: 'left', verticalAlign: 'top' }} key={'rating-opts-'+idx}>
                  <h3 style={{ textAlign: 'left', fontSize: '1rem' }}>{el.name} Rating</h3>
                  <RuxSlider max={100} min={0} minVal={el.min || 0} step={5} value={el.max || 100} axisLabels={[0,25,50,75,100]} onRuxchange={(e) => handleChange(e, idx)}/>
                  <RuxInput style={{ padding: '5px 0', maxWidth: '40%', display: 'inline-block', marginRight: '15px' }} label="Rating Min" type="text" size="medium" onKeyPress={ev => { if (ev.key === 'Enter') { ev.target.blur(); ev.preventDefault(); return; }; if (!/[0-9]/.test(ev.key)) ev.preventDefault() }} onRuxblur={(e) => onInputChange(e, idx, 'min', 100)} value={''+(el.min || 0)}/>
                  <RuxInput style={{ padding: '5px 0', maxWidth: '40%', display: 'inline-block' }} label="Rating Max" type="text" size="medium" onKeyPress={ev => { if (ev.key === 'Enter') { ev.target.blur(); ev.preventDefault(); return; }; if (!/[0-9]/.test(ev.key)) ev.preventDefault() }} value={''+(el.max || 100)} onRuxblur={(e) => onInputChange(e, idx, 'max', 100)}/>
                  {
                    el.votesMax > 0 ?
                      <div>
                        <h3 style={{ textAlign: 'left', fontSize: '1rem' }}>{el.name} Votes</h3>
                        <RuxSlider max={el.votesMax || 10000} min={0} step={el.votesMax*0.05} value={el.votes || 0} axisLabels={[0,el.votesMax*0.25,el.votesMax*0.5,el.votesMax*0.75,el.votesMax]} onRuxchange={(e) => handleChange(e, idx)}/>
                        <RuxInput style={{ padding: '5px 0', maxWidth: '40%', display: 'inline-block' }} label="Votes Min" type="text" size="medium" onKeyPress={ev => { if (ev.key === 'Enter') { ev.target.blur(); ev.preventDefault(); return; }; if (!/[0-9]/.test(ev.key)) ev.preventDefault() }} onRuxblur={(e) => onInputChange(e, idx, 'votes', el.votesMax)} value={''+(el.votes || 0)}/>
                      </div>
                    : null
                  }
                  <br/><br/>
                </div>
              ) : null
          }
        </div>
        {noItemsMessage}
        <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMore.bind(this)}
            hasMore={this.state.hasMoreItems}>
          {elements}
        </InfiniteScroll>
        <div style={{ height: '10px' }} />
        <div className="reached-end" style={{ marginBottom: '50px', display: this.state.order === 'popular' ? 'block' : 'none' }}>
          Popularity is sorted by seeds, only the top 100 torrents are taken into account.
        </div>
        <div className="reached-end">
          In order to guarantee fresh content, we show releases from the last 90 days only.
        </div>
        <div className="footer">
          <ul>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/legal'} style={{ fontSize: '16px', opacity: '0.9' }}>Legal</Link>
            </li>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/api-docs'} style={{ fontSize: '16px', opacity: '0.9' }}>API</Link>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/donate" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Donate</a>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Stream torrents</a>
            </li>
          </ul>
        </div>
        {stickyLegal}
      </div>
    );
  }
}
