import React from 'react';
import images from './images.js';
import './App.css';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet'


export default class App extends React.Component {
  pageTitle() {
     let docTitle = 'User Perks'
      return docTitle
  }
  pageDescription() {
    let docDesc = ''

    docDesc += 'Torrent Track: Subscribe for User Perks'

    return docDesc
  }
  componentWillMount() {
    localStorage.setItem('lastKnownUrl', window.location.pathname + window.location.search)
  }
  render() {
    return (
      <div className={'App Meta'} style={{ marginTop: '60px'}}>
        <Helmet>
          <title>{this.pageTitle() + ' - Torrent Track'}</title>
          <meta
            name="description"
            content={this.pageDescription()}
          />
        </Helmet>
        <div className="expand-width">
          <div className="sticky" style={{ display: 'block', marginLeft: '-10%', height: '21px' }}>
            <div className="sticky-inner" style={{ width: '80%' }}>
              <span style={{ float: 'right'}}><Link className={'blank-link meta-watchlist-button'} to={'/watchlist'}>My watchlist</Link></span>
              <h1 style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>Torrent Track User Perks</h1>
            </div>
          </div>
          <header className="App-header">
            <br/>
            <Link className="blank-link" style={{ zIndex: '100' }} to="/">
              <img src={images.logo} className="App-logo-meta" alt="Torrent Track logo" />
            </Link>
            <br/>
          </header>
          <div className={'header-buttons-holder'}>
            <Link className={'main-links meta-movie-button'} style={{ marginBottom: '15px' }} to={'/movies/'}>Movies</Link>
            <Link className={'main-links meta-series-button'} style={{ marginRight: '0' }} to={'/series/'}>Series</Link>
          </div>
          <div className={'full-size'} style={{ marginBottom: '45px', display: 'inline-block', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px', width: 'calc(100% - 40px)', textAlign: 'left' }}>
            <b style={{ fontSize: '19px' }}>Unlock Perks</b>
            <br/>
            <br/>
            You can unlock perks by logging in and providing a RPDB API Key, to receive an API Key you need to subscribe to one of the paid tiers on <a href="https://www.patreon.com/rpdb" target="_blank" className="blank-link href-with-underline" rel="noopener noreferrer">this page</a>.
            <br/>
            <br/>
            <b>Free</b>
            <br/>
- User Watchlist (no notifications)<br/>
- Install Addons (for external links to other websites / apps)<br/>
- Movies / Series from last 90 days<br/>
            <br/>
            <b>Tier 1</b>
            <br/>
- New Release Notifications<br/>
- Custom Lists (list configuration, save and export to Stremio / Sonarr / Radarr)<br/>
- Rating Filters (for IMDB / Rotten Tomatoes / Metacritic)<br/>
- Posters with Ratings in Stremio (for IMDB / Rotten Tomatoes / Metacritic)<br/>
- Movie / Series from last 3 months<br/>
            <br/>
            <b>Tier 2</b>
            <br/>
- Search Movies / Series<br/>
- Choose Ratings to Show on Site + Rating Filters (adds Letterboxd / Trakt / RogerEbert / TMDB / MyAnimeList)<br/>
- Configure Lists for More Ratings (adds Letterboxd / Trakt / RogerEbert / TMDB / MyAnimeList)
- Movies / Series from all time<br/>
            <br/>
            <b>Tier 3</b>
            <br/>
- Posters with Ratings in Stremio (adds Letterboxd / Trakt / RogerEbert / TMDB / MyAnimeList)<br/>
- VIP member
          </div>
        </div>
        <div className="footer">
          <ul>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/legal'} style={{ fontSize: '16px', opacity: '0.9' }}>Legal</Link>
            </li>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/api-docs'} style={{ fontSize: '16px', opacity: '0.9' }}>API</Link>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/donate" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Donate</a>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Stream torrents</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

