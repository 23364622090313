import React from 'react';
import images from './images.js';
import './App.css';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet'

export default class App extends React.Component {
  pageTitle() {
     let docTitle = 'Legal'
      return docTitle
  }
  pageDescription() {
    let docDesc = ''

    docDesc += 'Torrent Track: Cookie Policy, Privacy Policy and Terms of Service.'

    return docDesc
  }
  componentWillMount() {
    localStorage.setItem('lastKnownUrl', window.location.pathname + window.location.search)
  }
  render() {
    return (
      <div className={'App Meta'} style={{ marginTop: '60px'}}>
        <Helmet>
          <title>{this.pageTitle() + ' - Torrent Track'}</title>
          <meta
            name="description"
            content={this.pageDescription()}
          />
        </Helmet>
        <div className="expand-width">
          <div className="sticky" style={{ display: 'block', marginLeft: '-10%', height: '21px' }}>
            <div className="sticky-inner" style={{ width: '80%' }}>
              <span style={{ float: 'right'}}><Link className={'blank-link meta-watchlist-button'} to={'/watchlist'}>My watchlist</Link></span>
              <h1 style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>Torrent Track Legal</h1>
            </div>
          </div>
          <header className="App-header">
            <br/>
            <Link className="blank-link" style={{ zIndex: '100' }} to="/">
              <img src={images.logo} className="App-logo-meta" alt="Torrent Track logo" />
            </Link>
            <br/>
          </header>
          <div className={'header-buttons-holder'}>
            <Link className={'main-links meta-movie-button'} style={{ marginBottom: '15px' }} to={'/movies/'}>Movies</Link>
            <Link className={'main-links meta-series-button'} style={{ marginRight: '0' }} to={'/series/'}>Series</Link>
          </div>
          <div className={'full-size'} style={{ marginBottom: '45px', display: 'inline-block', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px', width: 'calc(100% - 40px)', textAlign: 'left' }}>
            <b style={{ fontSize: '19px' }}>Cookie Policy</b>
            <br/>
            <br/>
            We use cookies for: your personal watchlist, agreement to legal notification and your addon choices.
            <br/>
            <br/>
            <br/>
            <b style={{ fontSize: '19px' }}>Privacy Policy</b>
            <br/>
            <br/>
            We do not store any personal data or analytics of any kind.
            <br/>
            <br/>
            <br/>
            <b style={{ fontSize: '19px' }}>Disclaimer</b>
            <br/>
            <br/>
            This service exists for the purpose of analytical data on new / popular torrent releases. It does not allow streaming, give any direct torrent links, download links or magnet links.
            <br/>
            <br/>
            A simplistic addon system is put in place, with community built addons that the users can install and use based on their own moral judgement. The addons can only add external links to websites for additional information on the media.
            <br/>
            <br/>
            We do not endorse breach of copyright.
          </div>
        </div>
        <div className="footer">
          <ul>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/legal'} style={{ fontSize: '16px', opacity: '0.9' }}>Legal</Link>
            </li>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/api-docs'} style={{ fontSize: '16px', opacity: '0.9' }}>API</Link>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/donate" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Donate</a>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Stream torrents</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

