import React from 'react';
import images from './images.js';
import './App.css';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet'

export default class App extends React.Component {
  pageTitle() {
    return 'API Docs'
  }
  pageDescription() {
    return 'API for metadata and movie / series lists from Torrent Track, includes IMDB, Metacritic, Rotten Tomatoes scores.'
  }
  componentWillMount() {
    localStorage.setItem('lastKnownUrl', window.location.pathname + window.location.search)
  }
  render() {

    return (
      <div className={'App Meta'} style={{ marginTop: '60px'}}>
        <Helmet>
          <title>{this.pageTitle() + ' - Torrent Track'}</title>
          <meta
            name="description"
            content={this.pageDescription()}
          />
        </Helmet>
        <div className="expand-width">
          <div className="sticky" style={{ display: 'block', marginLeft: '-10%', height: '21px' }}>
            <div className="sticky-inner" style={{ width: '80%' }}>
              <span style={{ float: 'right'}}><Link className={'blank-link meta-watchlist-button'} to={'/watchlist'}>My watchlist</Link></span>
              <h1 style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>Torrent Track API</h1>
            </div>
          </div>
          <header className="App-header">
            <br/>
            <Link className="blank-link" style={{ zIndex: '100' }} to="/">
              <img src={images.logo} className="App-logo-meta" alt="Torrent Track logo" />
            </Link>
            <br/>
          </header>
          <div className={'header-buttons-holder'}>
            <Link className={'main-links meta-movie-button'} style={{ marginBottom: '15px' }} to={'/movies/'}>Movies</Link>
            <Link className={'main-links meta-series-button'} style={{ marginRight: '0' }} to={'/series/'}>Series</Link>
          </div>
        <div style={{ padding: '20px', margin: '20px 0', textAlign: 'center', background: 'rgba(0,0,0,0.3)', marginBottom: '45px' }}>
          Consider <a className={('blank-link')} rel="noopener noreferrer" href="https://powder.media/donate" target="_blank" style={{ textDecoration: 'underline' }}>donating</a> to keep this API free and functional in the future.
        </div>
        <div style={{ textAlign: 'center' }}>
          <b>Lists:</b>
          <br/>
          <div style={{ display: 'inline-block', padding: '15px', backgroundColor: 'rgba(0,0,0,0.2)', marginTop: '15px', marginBottom: '15px' }}>
            {'https://api.torrent-track.app/{type}/{list}.json'}
          </div>
          <br/>
          <span style={{ fontSize: '15px', opacity: '0.8' }}>where {'"{type}"'} can be "movies", "series", "other" and {'"{list}"'} can be "new", "popular", "best"</span>
          <div style={{ height: '30px' }}/>
          <b>Metadata from IMDB ID:</b>
          <br/>
          <div style={{ display: 'inline-block', padding: '15px', backgroundColor: 'rgba(0,0,0,0.2)', marginTop: '15px', marginBottom: '15px' }}>
            {'https://api.torrent-track.app/meta/{imdbId}.json'}
          </div>
          <br/>
          <span style={{ fontSize: '15px', opacity: '0.8' }}>where {'"{imdbId}"'} is any valid IMDB ID, such as "tt9484998"</span>
          <div style={{ height: '50px' }}/>
        </div>
      </div>
        <div className="footer">
          <ul>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/legal'} style={{ fontSize: '16px', opacity: '0.9' }}>Legal</Link>
            </li>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/api-docs'} style={{ fontSize: '16px', opacity: '0.9' }}>API</Link>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/donate" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Donate</a>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Stream torrents</a>
            </li>
          </ul>
        </div>
    </div>
    );
  }
}

